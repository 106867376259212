import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import translations from "@app/assets/translations.json";
import { useCookiesService } from "@app/modules/Cookies/services/CookiesService";

export const Footer = () => {
  const { i18n } = useTranslation(["landingpage"]);
  const links: any[] = useMemo(() => (translations as any)[i18n.resolvedLanguage].landingpage.footer.links, [i18n.resolvedLanguage]);

  const { setModalOpen } = useCookiesService();

  return (
    <div className="flex flex-col items-center justify-center my-8 text-sm font-light phablet:my-6 laptop:flex-row text-gray-dark laptop:divide-x laptop:divide-gray">
      {links &&
        links.map((link: any, index) =>
          link.link === "internal:cookies" ? (
            <button key={index} className="px-4 py-3" onClick={() => setModalOpen(true)}>
              {link.name}
            </button>
          ) : (
            <a key={index} href={link.link} target="_blank" className="px-4 py-3" rel="noreferrer">
              {link.name}
            </a>
          )
        )}
    </div>
  );
};

{
  /* <a href={t("footer.link1")} target="blank" className="mx-4 my-3 cursor-pointer">
{t("footer.link.name1")}
</a>
<span className="hidden laptop:flex border-l-[1px] h-[30px] border-gray" />
<a href={t("footer.link2")} target="blank" className="mx-4 my-3 cursor-pointer">
{t("footer.link.name2")}
</a>

<span className="hidden laptop:flex border-l-[1px] h-[30px] border-gray" />
<a href={t("footer.link3")} target="blank" className="mx-4 my-3 cursor-pointer">
{t("footer.link.name3")}
</a>

<span className="hidden laptop:flex border-l-[1px] h-[30px] border-gray" />
<a href={t("footer.link4")} target="blank" className="mx-4 my-3 cursor-pointer">
{t("footer.link.name4")}
</a> */
}
